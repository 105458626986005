<template>
	<dl class="flex flex-col gap-2">
		<div
			v-for="(item, i) in items"
			:key="i"
			class="rounded-xl border-2 dark:text-gray-200 border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 transition-all duration-300"
		>
			<dt class="mx-4">
				<button
					:id="`base-expansion-list-button-${i}`"
					class="w-full flex justify-between ml-auto py-2"
					:class="[{ 'cursor-not-allowed': item.disabled }]"
					:disabled="item.disabled"
					:aria-disabled="item.disabled"
					:aria-expanded="activeContent === i"
					:aria-controls="`base-expansion-list-content-${i}`"
					@click.prevent="toggleContent(i)"
				>
					<div class="text-xl w-full">
						<slot name="header" v-bind="{ i, ...item, isActive: activeContent === i }">
							<span class="sr-only">
								{{ activeContent === i ? 'Hide' : 'Show' }} content for
							</span>

							{{ item.label }}
						</slot>
					</div>
					<FAIcon
						v-if="!item.disabled"
						icon="chevron-down"
						class="transition-transform my-auto p-2"
						:class="{ 'rotate-180': activeContent === i }"
						aria-hidden="true"
					/>
				</button>
			</dt>
			<BaseExpand
				:scroll-into-view="scrollIntoView"
				:expanded="activeContent === i"
				:content-id="`base-expansion-list-content-${i}`"
			>
				<dd
					:aria-labelledby="`base-expansion-list-button-${i}`"
				>
					<slot name="content" v-bind="{ i, ...item }" />
				</dd>
			</BaseExpand>
		</div>
	</dl>
</template>

<script setup>
import { ref } from 'vue';

import BaseExpand from '@/components/ui/BaseExpand';

const props = defineProps({
	items: { type: Array, required: true },
	initialActive: { type: Number, default: null },
	scrollIntoView: { type: Boolean, default: false },
});

const activeContent = ref(props.initialActive);

async function toggleContent(panel) {
	activeContent.value = activeContent.value === panel ? null : panel;
}
</script>
