import useConfigStore from '@/stores/site/config';
import useApptSchedulerStore from '@/stores/agent/agent-appt-scheduler';

export default [
	{
		path: '',
		redirect: { name: 'dashboard' },
		meta: { showInNav: false },
	},
	{
		path: 'dashboard/',
		name: 'dashboard',
		component: () => import('@/views/Agents/AgentDashboard'),
		meta: {
			exact: false,
			icon: 'gauge-high',
			label: 'Dashboard',
			showInNav: true,
			title: 'MySFDomain - Agent Dashboard',
		},
	},
	{
		path: 'domains/',
		strict: true,
		children: [
			{
				path: '',
				name: 'domains',
				component: () => import('@/views/Agents/Domains/DomainList'),
				meta: {
					title: 'Domains',
				},
			},
			{
				path: 'purchase/',
				name: 'purchase',
				component: () => import('@/views/Agents/Domains/DomainPurchase'),
				beforeEnter: checkForDisabledDomains,
				meta: {
					title: 'Purchase Domain',
				},
			},

			{
				path: 'transfer/',
				name: 'transfer domain',
				component: () => import('@/views/Agents/Domains/DomainTransfer'),
				beforeEnter: checkForDisabledDomains,
				meta: {
					title: 'Transfer Domain',
				},
			},
		],
		meta: {
			exact: false,
			title: 'Domains',
			icon: 'earth',
			showInNav: true,
		},
	},

	{
		path: 'emails/',
		name: 'emails',
		component: () => import('@/views/Agents/EmailRedirects'),
		meta: {
			title: 'Emails',
			showInNav: true,
			icon: 'envelope',
		},
	},
	{
		path: 'campaigns/',
		name: 'campaigns',
		component: () => import('@/views/Agents/CampaignStats'),
		meta: {
			title: 'Campaigns',
			label: 'Campaigns',
			showInNav: true,
			icon: 'file',
		},
	},
	{
		path: 'calltracking/',
		name: 'calltracking',
		components: {
			default: () => import('@/views/Agents/CallTracking'),
		},
		meta: {
			title: 'Call Tracking',
			showInNav: true,
			icon: 'phone',
		},
	},
	{
		path: 'appointment-scheduler/',
		beforeEnter: checkIfApptSchedulingIsReleased,
		meta: {
			title: 'Appointments',
			showInNav: false,
			icon: 'calendar',
		},
		children: [
			{
				path: '',
				name: 'appointment scheduler pitch',
				component: () =>
					import('@/components/appointment-scheduler/AppointmentSchedulerPitch'),
				beforeEnter: redirectToSchedules,
				meta: {
					title: 'Appointment Scheduler Pitch',
				},
			},
			{
				path: 'appointments/',
				name: 'appointments',
				component: () => import('@/components/appointment-scheduler/AppointmentsTable'),
				beforeEnter: redirectToApptSchedulerPitch,
				meta: {
					title: 'See Upcoming and Past Appointments',
				},
			},
			{
				path: 'schedules/',
				name: 'schedules',
				component: () => import('@/components/appointment-scheduler/ScheduleList'),
				beforeEnter: redirectToApptSchedulerPitch,
				meta: {
					title: 'Schedule Staff Availability',
				},
			},

			{
				path: 'settings/',
				name: 'settings',
				component: () =>
					import('@/components/appointment-scheduler/AppointmentSchedulerSettings'),
				beforeEnter: redirectToApptSchedulerPitch,
				meta: {
					title: 'Settings for All Schedules',
				},
			},
		],
	},
	{
		path: 'pricing/',
		name: 'pricing',
		component: () => import('@/views/Agents/Domains/DomainPricing'),
		meta: {
			title: 'Pricing',
			showInNav: true,
			icon: 'dollar-sign',
		},
	},
	{
		path: 'payment/',
		name: 'payment',
		component: () => import('@/views/Agents/PaymentSettings'),
		meta: {
			title: 'Payment Info',
			showInNav: true,
			icon: 'credit-card',
		},
	},
	{
		path: 'billing/',
		meta: {
			title: 'Billing History',
			showInNav: true,
			icon: 'money-bill',
		},
		children: [
			{
				path: '',
				name: 'billing',
				component: () => import('@/views/Agents/BillingHistory'),
				meta: {
					title: 'Billing History',
				},
			},
			{
				path: 'comp-deduct-state-taxes/',
				name: 'comp-deduct-state-taxes',
				component: () => import('@/views/Agents/CompDeductStateTaxes'),
				meta: {
					title: 'Comp Deduct State Taxes',
				},
			},
			{
				path: ':receipt/',
				name: 'Receipt',
				component: () => import('@/views/Agents/BillingSummary'),
			},
		],
	},
	{
		path: 'team/',
		name: 'team',
		component: () => import('@/views/Agents/TeamPermissions'),
		meta: {
			label: 'Team Permissions',
			title: 'Manage Team Permissions',
			showInNav: true,
			icon: 'people-group',
		},
	},
	{
		path: 'welcome/',
		name: 'welcome',
		component: () => import('@/views/Agents/NewAgent'),
		meta: {
			showInNav: false,
		},
	},
];
function checkForDisabledDomains(to, from, next) {
	const configStore = useConfigStore();
	const enabled = configStore.domainTransactionsEnabled;
	if (enabled) {
		next();
	} else {
		next({ name: 'domains', params: { ...to.params } });
	}
}
async function redirectToApptSchedulerPitch(to, from, next) {
	const apptSchedulerStore = useApptSchedulerStore();

	if (!apptSchedulerStore.hasAS) {
		await apptSchedulerStore.getAS(to.params.agentID);
	}

	if (apptSchedulerStore.hasAS) {
		next();
	} else {
		next({ name: 'appointment scheduler pitch', params: { ...to.params } });
	}
}
async function redirectToSchedules(to, from, next) {
	const apptSchedulerStore = useApptSchedulerStore();

	if (!apptSchedulerStore.hasAS) {
		await apptSchedulerStore.getAS(to.params.agentID);
	}

	if (apptSchedulerStore.hasAS) {
		next({ name: 'schedules', params: { ...to.params } });
	} else {
		next();
	}
}
function checkIfApptSchedulingIsReleased(to, from, next) {
	const apptSchedulerStore = useApptSchedulerStore();
	if (apptSchedulerStore.ENABLED_FOR_ALL_AGENTS || to.params.agentID === '0XDEADBEEFD') {
		next();
	} else {
		next({ name: 'dashboard', params: { ...to.params } });
	}
}
