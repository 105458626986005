<template>
	<nav
		v-show="drawerWidth !== null"
		id="navigationDrawer"
		class="transition-width flex h-full flex-col bg-gray-800 duration-300 ease-in-out"
		:class="drawerWidthClasses"
	>
		<slot name="skipLink" :inert="!drawerOpen" />
		<div
			class="flex-1 overflow-y-auto overflow-x-clip text-white"
			:inert="!drawerOpen && drawerWidth === 'none'"
		>
			<router-view name="navigation" />
		</div>
		<div class="pb-2">
			<hr class="mb-2 border-gray-600" />
			<div class="flex w-full justify-end px-2" :class="{ 'justify-center': !drawerOpen }">
				<BaseTooltip
					v-if="drawerOpen || collapseTo !== 'none'"
					ref="tooltip0"
					:position="drawerOpen ? 'top' : 'right'"
					tooltip-id="toggle-drawer-0"
					:text="`${drawerOpen ? 'Collapse' : 'Expand'} Sidebar`"
					hover-delay="1000"
					hide-delay="0"
					:shortcuts="['shift', '.']"
				>
					<button
						class="flex rounded px-3 py-2 transition hover:bg-gray-700"
						:class="{ 'mx-auto': drawerWidth !== expandTo }"
						:aria-expanded="drawerOpen"
						:aria-label="`${drawerOpen ? 'Hide' : 'Show'} navigation drawer`"
						@click="
							drawerWidth = drawerOpen ? collapseTo : expandTo;
							tooltip0.hide();
						"
					>
						<FAIcon
							v-if="drawerOpen"
							icon="left-to-line"
							size="lg"
							class="text-gray-100"
						/>
						<FAIcon
							v-else-if="collapseTo !== 'none'"
							icon="right-from-line"
							size="lg"
							class="text-gray-100"
						/>
					</button>
				</BaseTooltip>
			</div>
		</div>
		<div v-show="showNavigation && drawerHidden" class="absolute bottom-4 left-4 z-10">
			<BaseTooltip
				ref="tooltip1"
				position="right"
				tooltip-id="toggle-drawer-1"
				text="Expand/Collapse Sidebar"
				hover-delay="1000"
				hide-delay="0"
				:shortcuts="['shift', '.']"
			>
				<button
					class="z-10 rounded-lg bg-gray-700 px-3 py-2 transition hover:bg-gray-600"
					aria-expanded="false"
					aria-label="Show navigation drawer"
					@click="
						drawerWidth = expandTo;
						tooltip1.hide();
					"
				>
					<FAIcon icon="right-from-line" size="lg" class="text-gray-100" />
				</button>
			</BaseTooltip>
		</div>
	</nav>
</template>
<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';

import useNavigationStore from '@/stores/navigation';
import BaseTooltip from '@/components/ui/BaseTooltip';

const navigationStore = useNavigationStore();
const { drawerWidth, collapseTo, expandTo, showNavigation, drawerOpen } =
	storeToRefs(navigationStore);

const windowWidth = ref(window.innerWidth);
const tooltip0 = ref(null);
const tooltip1 = ref(null);

const drawerWidthClasses = computed(() => {
	if (windowWidth.value < 640) {
		if (drawerOpen.value) {
			return 'w-full';
		}
		return 'w-0';
	}
	return {
		none: 'w-0',
		sm: 'w-16',
		md: 'w-60',
		lg: 'w-72',
		xl: 'w-80',
		full: 'w-full',
	}[!showNavigation.value ? 'none' : drawerWidth.value];
});

const drawerHidden = computed(() => {
	if (windowWidth.value < 640) {
		return !drawerOpen.value;
	}
	return drawerWidth.value === 'none';
});

function toggleDrawer(event) {
	if (event.shiftKey && event.key === '>') {
		const ignoredElements = ['INPUT', 'TEXTAREA'];
		if (ignoredElements.includes(event.target.tagName) || event.target.isContentEditable) {
			return;
		}
		event.preventDefault();
		drawerWidth.value = drawerOpen.value ? collapseTo.value : expandTo.value;
		tooltip0.value.hide();
		tooltip1.value.hide();
	}
}

function handleResize() {
	windowWidth.value = window.innerWidth;
}

onMounted(() => {
	handleResize();
	window.addEventListener('keydown', toggleDrawer);
	window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
	window.removeEventListener('keydown', toggleDrawer);
	window.removeEventListener('resize', handleResize);
});
</script>
