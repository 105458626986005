<template>
	<div class="bg-gray-100 dark:bg-gray-800 p-2 pt-0">
		<div class="flex gap-2">
			<div class="basis-3/5 rounded-md bg-white dark:bg-gray-700 p-4">
				<h4 class="text-2xl font-semibold leading-none text-gray-700 dark:text-gray-100">
					Edit Contact
				</h4>
				<!-- Contact Form -->
				<form class="flex flex-col" @submit="updateContact">
					<div class="flex flex-col gap-3 py-5 xl:flex-row">
						<TextFieldInput
							v-model="email"
							:disabled="loading"
							class="grow"
							label="New Contact Email"
							placeholder="email@email.com"
							type="email"
						>
							<template #hint> format: name@domain.com </template>
							<template v-if="errors.email" #message>
								{{ errors.email }}
							</template>
						</TextFieldInput>

						<TextFieldInput
							v-model="phone"
							:disabled="loading"
							class="grow"
							clearable
							label="New Contact Phone"
							placeholder="2223334444"
							type="tel"
						>
							<template #hint> format: 2223334444 </template>
							<template v-if="errors?.phone" #message>
								{{ errors.phone }}
							</template>
						</TextFieldInput>
					</div>

					<CheckboxGroup
						v-model:values="contactTypes"
						name="contact-types"
						:options="CONTACT_OPTIONS"
						legend="Registrar contact types"
					/>

					<BaseButton
						color="primary"
						type="submit"
						:disabled="!meta.valid || !meta.dirty || loading"
						:loading="loading"
						class="self-end"
					>
						Update Contact(s) Details
					</BaseButton>
				</form>
			</div>
			<!-- Existing Contacts  -->
			<div class="basis-2/5 rounded-md bg-white dark:bg-gray-700">
				<LoadingSpinner v-if="loading" class="mt-16" text="Loading contacts..." />
				<BaseAlert v-else-if="!contacts" type="error" class="h-full">
					There was a problem loading up this domains contacts. Go ask a dev!
				</BaseAlert>
				<SortableTable
					v-else
					:headers="CONTACT_HEADERS"
					:items="contacts"
					title="Existing Contacts"
					show-title
					item-unique-key="contact_type"
					class="rounded-md p-4"
				/>
			</div>
		</div>
		<div class="mt-2 flex gap-2">
			<!-- Authcode -->
			<div class="rounded-md bg-white dark:bg-gray-700 p-4 basis-1/3">
				<LoadingSpinner v-if="loading" class="!h-8" />
				<div v-else class="flex flex-col items-center justify-evenly gap-4 xl:flex-row">
					<div v-if="authcode" class="flex flex-col">
						<span>
							<b>Authcode: </b>
							<code>{{ authcode }}</code>
						</span>
						<span> <b>Timestamp: </b> {{ authcodeTimestamp }} </span>
					</div>
					<div v-else><b>Authcode: </b> Unknown</div>

					<BaseButton color="primary" @click="refreshAuthcode()">
						Get Authcode
					</BaseButton>
				</div>
			</div>

			<div class="rounded-md bg-white dark:bg-gray-700 p-4 basis-2/3">
				<LoadingSpinner v-if="loading" class="!h-8" />
				<div v-else class="flex flex-col h-full items-center justify-evenly gap-4 2xl:flex-row">
				    <span v-if="markedForTransferBy">
					Marked For Transfer
					by <b>{{markedForTransferBy}}</b>
					on <b>{{markedForTransferOnTimestamp}}.</b>
				    </span>
				    <span v-if="markedForTransferBy">
					<b>{{ transferAllowedStatus }}</b> at Registrar
				    </span>
				    <BaseButton color="primary" @click="toggleMarkForTransfer()">
					<span v-if="markedForTransferBy">
					    Lock & Remove Mark
					</span>
					<span v-else>
					    Unlock & Mark For Transfer
					</span>
				    </BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';
import { useForm } from 'vee-validate';
import { array as yArray, object as yObject, string as yString } from 'yup';

import { useApi } from '@/composables/useApi';

import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import CheckboxGroup from '@/components/ui/CheckboxGroup.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
const CONTACT_OPTIONS = [{ value: 'admin' }, { value: 'billing' }, { value: 'technical' }];
const CONTACT_HEADERS = [
	{ text: 'Type', value: 'contact_type', sortable: false },
	{ text: 'Email', value: 'email', sortable: false },
	{ text: 'Phone', value: 'phone', sortable: false },
];

const props = defineProps({
	name: { type: String, required: true },
});

const loading = ref(true);

const authcode = ref(null);
const authcodeUpdatedAt = ref(null);
const contacts = ref([]);
const transferAllowed = ref(null);
const agentID = ref(null);
const isMarkedForTransfer = ref(false);
const markedForTransferBy = ref(null);
const markedForTransferOn = ref(null);

const { meta, defineField, errors, handleSubmit, handleReset } = useForm({
    initialValues: {
		email: '',
		phone: '',
		contactTypes: ['admin', 'billing', 'technical'],
	},
	validationSchema: yObject({
		email: yString().email().required(),
		phone: yString()
			.required()
			.matches(/^\d{10}$/),
		contactTypes: yArray().of(yString().oneOf(CONTACT_OPTIONS.map(o => o.value))),
	}),
});

const [email] = defineField('email', { label: `New Contact Email` });
const [phone] = defineField('phone', {});
const [contactTypes] = defineField('contactTypes');

const authcodeTimestamp = computed(() => format(new Date(authcodeUpdatedAt.value), 'PPPppp'));
const transferAllowedStatus = computed(() => {
    if (transferAllowed.value === null || transferAllowed.value === undefined) {
	return 'Unknown';
    }
    return transferAllowed.value ? 'Transfer unlocked' : 'Transfer locked';
});

const markedForTransferOnTimestamp =  computed(() => format(new Date(markedForTransferOn.value), 'PPPppp'));

async function refreshAuthcode() {
    loading.value = true;

	try {
		const { data } = await useApi(
			`api/agents/${agentID.value}/domains/${props.name}/authcode/`,
			{
				message:
					'There was an issue getting the domains authcode data! Please try again later.',
			}
		)
			.put()
			.json();

		authcode.value = data.value?.authcode;
		authcodeUpdatedAt.value = data.value?.authcodeUpdatedAt;
	} finally {
		loading.value = false;
	}
}

async function toggleMarkForTransfer() {
    loading.value = true;

    try {
	const { data } = await useApi(
	    `api/agents/${agentID.value}/domains/${props.name}/toggle_mark_for_transfer/`,
	    {
		message:'There was an issue marking this domain for transfer! Please try again later.',
	    }
	).put()
	await getRegistrarData()
    } finally {
	loading.value = false;
    }

}

const updateContact = handleSubmit(async () => {
    loading.value = true;

	try {
		const { data } = await useApi(
			`api/agents/${agentID.value}/domains/${props.name}/registrar/`,
			{
				message: 'There was an issue updating the registrar contact email',
			}
		)
			.post({
				email: email.value,
				phone: phone.value,
				contactTypes: contactTypes.value,
			})
			.json();

		contacts.value = data.value?.contacts;
		handleReset();
	} finally {
		loading.value = false;
	}
});

async function getRegistrarData() {
    console.log(agentID.value)
    try {
	const { data } = await useApi(
	    `api/agents/${agentID.value}/domains/${props.name}/registrar/`,
	    {
		message:
					'There was an issue getting the domains registrar data! Please try again later.',
	    }
	).json();

	authcode.value = data.value?.authcode;
	authcodeUpdatedAt.value = data.value?.authcodeUpdatedAt;
	transferAllowed.value = data.value?.transfer_allowed;
	contacts.value = data.value?.contacts;
	markedForTransferBy.value = data.value?.markedForTransferBy;
	markedForTransferOn.value = data.value?.markedForTransferOn;
    } finally {
	loading.value = false;
    }
}

onMounted(async () => {
    agentID.value = useRoute().params.agentID;
    await getRegistrarData()
});
</script>
