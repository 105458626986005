<template>
	<header
		:class="[
			warnAboutProd
				? 'border-b-red bg-blue-100 dark:bg-red-950'
				: 'border-b-orange bg-white dark:bg-gray-900',
		]"
		class="sticky top-0 z-50 flex h-20 items-center justify-between gap-x-2 border-b-2 px-6 print:hidden"
	>
		<slot name="skipLink" />

		<div class="flex items-center">
			<AppLink :to="mainLogoLink" class="!no-underline">
				<div
					class="flex flex-col items-center text-sm font-bold leading-none text-gray-700 dark:text-gray-200 sm:flex-row sm:gap-x-1 md:text-2xl xl:gap-x-2 xl:text-4xl"
				>
					<div class="">my</div>
					<span
						class="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-700 bg-gray-100 shadow-xl dark:bg-orange-600 lg:h-10 lg:w-10 lg:border-4 xl:h-16 xl:w-16"
					>
						<span
							class="md:text-md text-sm font-extrabold leading-none text-orange-600 dark:text-gray-100 xl:text-4xl"
						>
							SF
						</span>
					</span>
					<div class="">domain</div>
					<span v-if="overview" class="uppercase tracking-widest">overview</span>
				</div>
			</AppLink>
		</div>

		<p
			v-if="devMode"
			class="!mt-0 flex-1 truncate text-center text-xs uppercase tracking-widest text-orange-400"
		>
			dev mode dev mode dev mode dev mode dev mode dev <br />
			dev mode dev mode dev mode dev mode dev mode dev <br />
			dev mode dev mode dev mode dev mode dev mode dev <br />
			dev mode dev mode dev mode dev mode dev mode dev <br />
		</p>

		<div class="flex items-center gap-x-2 whitespace-nowrap">
			<div class="flex flex-col items-end">
				<AppLink class="space-x-2" color="gray" href="tel:18778283131">
					<span class="font-bold md:hidden">Call</span>
					<span class="font-bold max-md:hidden">1-877-828-3131</span>
					<FAIcon class="max-sm:hidden" icon="phone" />
				</AppLink>

				<AppLink class="space-x-2" color="gray" href="mailto:support@mysfdomain.com">
					<span class="font-bold">Email <span class="max-md:hidden">Support</span></span>
					<FAIcon class="max-sm:hidden" icon="envelope" />
				</AppLink>
			</div>
			<MirusOnlyBorder dense inline>
				<template #fallback>
					<div
						class="h-12 w-12 shrink-0 rounded-full border-gray-600 bg-white shadow-lg max-md:hidden"
					>
						<img
							:src="mirusLogo"
							alt="Mirus Research loves domains"
							class="aspect-square w-full rounded-full bg-orange-500 p-1"
						/>
					</div>
				</template>
				<div class="flex items-center gap-2">
					<div
						class="hidden sm:flex h-8 items-center rounded-full bg-orange-200 px-2 dark:bg-orange-900"
					>
						<BaseToggle
							:model-value="isDark"
							class="shrink-0"
							@update:model-value="toggleDark"
						>
							Dark mode
						</BaseToggle>
					</div>
					<BaseSelect
						v-if="devMode"
						id="host-target"
						v-model="hostTarget"
						class="hidden sm:block"
						:items="['mysfdomain.local', 'mysfdomain']"
						label="backend host"
						@update:model-value="reload"
					/>
					<ViewAsUser />
					<button
						class="h-8 w-8 shrink-0 rounded-full bg-orange-600 p-1 shadow shadow-gray-800/25 transition-colors hover:bg-orange-500"
						@click="$emit('update:mirusControls', !mirusControls)"
					>
						<img :src="mirusLogo" alt="Mirus Research loves domains" />
					</button>
				</div>
			</MirusOnlyBorder>
		</div>
	</header>
</template>
<script setup>
import { computed, nextTick, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useDark } from '@vueuse/core';

import mirusLogo from '@/assets/images/editor/logo/mirusControls.svg';

import useSupportStore from '@/stores/support';
import useUserStore from '@/stores/user';

import BaseToggle from '@/components/ui/BaseToggle.vue';
import BaseSelect from '@/components/ui/BaseSelect';
import AppLink from '@/components/ui/AppLink.vue';
import MirusOnlyBorder from '@/components/common/MirusOnlyBorder';

import ViewAsUser from '@/components/ViewAsUser';

defineProps({
	mirusControls: { type: Boolean, default: false },
});
defineEmits(['update:mirusControls']);
const route = useRoute();

const supportStore = useSupportStore();
const { hostTarget, warnAboutProd, devMode } = storeToRefs(supportStore);

const userStore = useUserStore();
const { isMirusUser } = storeToRefs(userStore);

const overview = computed(() => route.path.match('/overview'));

const mainLogoLink = computed(() => {
	if (overview.value) {
		return { name: 'overview' };
	}

	if (route.params?.agentID) {
		return {
			name: 'dashboard',
			params: {
				agentID: route.params?.agentID,
			},
		};
	}

	return { name: 'index' };
});

function reload(newTarget) {
	if (hostTarget.value !== newTarget) {
		window.location.reload();
	}
}

const isDark = useDark();
function toggleDark() {
	isDark.value = !isDark.value;
}

// function that adds keyboard shortcut to toggle dark mode
function toggleDarkMode(event) {
	const ignoredElements = ['INPUT', 'TEXTAREA'];
	if (ignoredElements.includes(event.target.tagName) || event.target.isContentEditable) {
		return;
	}
	if (!isMirusUser.value) {
		return;
	}
	if (event.shiftKey && event.key === 'D') {
		toggleDark();
	}
}

onMounted(() => {
	window.addEventListener('keydown', toggleDarkMode);
});

onUnmounted(() => {
	window.removeEventListener('keydown', toggleDarkMode);
});
</script>
