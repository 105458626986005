import { EditorViews } from '@/views/MXEditor/views';

export default [
	{
		path: '',
		name: 'editorLanding',
		component: () => import('@/views/MXEditor/editor/EditorLanding'),
	},
	{
		path: ':page/',
		component: () => import('@/views/MXEditor/EditorPageView'),
		children: [
			{
				path: ':editor/',
				children: [
					{
						path: '',
						name: 'editor',
						redirect: { name: 'edit' },
					},
					{
						name: 'edit',
						path: 'edit/',
						beforeEnter(to, from, next) {
							const targetEditor = EditorViews.find(
								({ path, component }) => path === to.params.editor && component
							);
							if (targetEditor) {
								next();
							} else {
								next({ params: to.params, name: 'help' });
							}
						},
						component: () => import('@/views/MXEditor/EditorComponentPicker'),
						meta: {
							title: route =>
								`${
									EditorViews.find(({ path }) => path === route.params.editor)
										?.label || ''
								} Editor for ${route.params.domainName} `,
						},
					},
					{
						name: 'help',
						path: 'help/',
						component: () => import('@/views/MXEditor/EditorComponentPicker'),
						props: { help: true },
						meta: {
							title: route =>
								`${
									EditorViews.find(
										({ path, component }) =>
											path === route.params.editor && component
									)?.label || ''
								} Help Content`,
						},
					},
					{
						name: 'live',
						path: 'live/',
						component: () => import('@/components/MXEditor/LiveView.vue'),
						meta: { title: route => `Live view of ${route.params.domainName}` },
					},
				],
			},
		],
		props: true,
	},
	{
		path: 'non-editable/',
		name: 'nonEditableContent',
		component: () => import('@/views/MXEditor/NonEditableContent'),
	},
];
