<template>
	<BaseAlert v-if="responseErrors" class="mb-4" type="error">
		<ul>
			<li v-for="(errorValue, errorKey) in responseErrors" :key="errorKey">
				<strong>{{ errorKey }}: </strong>
				<span>{{ errorValue }}</span>
			</li>
		</ul>
	</BaseAlert>

	<BaseAlert v-if="status === 'done'" type="success" class="mb-4">
		Domain was successfully moved!
	</BaseAlert>

	<Form
		v-slot="{ handleSubmit, meta }"
		class="flex gap-4 grow text-gray-600 dark:text-gray-300 h-full"
		tag="section"
		@submit="onMove"
	>
		<div class="rounded-lg bg-white dark:bg-gray-800 p-4 overflow-y-auto basis-1 sm:basis-7/12">
			<Field
				v-if="movableDomains"
				v-slot="{ field }"
				rules="required"
				name="Selected Domain"
				type="object"
			>
				<RadioGroup
					id="selectedDomain"
					v-model="selectedDomain"
					name="selectedDomainRadio"
					:options="activeMovableDomains"
					option-key="name"
					legend="Movable Domains"
					v-bind="field"
				>
					<template #label="{ name }">
						<span> {{ name }} </span>
					</template>
				</RadioGroup>
				<div v-if="inactiveOrUnmovableDomains.length" class="mt-6">
					<h4 class="text-xl font-semibold text-black dark:text-gray-100 mb-2">
						Unmovable Domains
					</h4>
					<ul class="list-disc pl-6">
						<li
							v-for="domain in inactiveOrUnmovableDomains"
							:key="domain.name"
							class="mb-1"
						>
							{{ domain.name }}
							<span v-if="!domain.active" class="text-gray-500 dark:text-gray-400">
								(inactive)</span
							>
							<span v-if="!domain.can_move" class="text-gray-500 dark:text-gray-400">
								(not movable, owned by someone outside mirus)</span
							>
						</li>
					</ul>
				</div>
			</Field>

			<LoadingSpinner v-else class="mt-16" text="Loading domains..." />
		</div>
		<div class="flex flex-col p-4 rounded-lg bg-white dark:bg-gray-800 sm:basis-5/12">
			<Field v-slot="{ field, errors }" :rules="aliasLength" name="Selected Alias">
				<TextFieldInput
					v-model="selectedAlias"
					v-bind="field"
					light
					:disabled="status === 'moving'"
					label="Enter a valid Agent Alias"
					placeholder="alias"
					:error-message="errors[0]"
					class="mb-2"
				/>
			</Field>

			<Field
				v-slot="{ handleChange, handleBlur, errors }"
				name="Domain type"
				:rules="domainTypeChosen"
			>
				<BaseSelect
					id="domain_type_for_moved_domain"
					v-model="selectedProductType"
					outlined
					show-label
					:disabled="status === 'moving'"
					:error-messages="errors"
					label="Target Agents Domain Type"
					padding="8"
					:items="domainTypeOptions"
					@update:model-value="handleChange"
					@blur="handleBlur"
					class="mb-4"
				>
					<template v-if="errors.length > 0" #message> {{ errors[0] }} </template>
				</BaseSelect>
			</Field>

			<BaseToggle
				v-model="shouldRemoveRedirects"
				class="!mt-0"
				visible-label="Remove all existing email redirects?"
			/>
			<BaseButton :disabled="!meta.dirty || !meta.valid" color="primary" type="submit">
				{{ status === 'moving' ? '...Moving' : 'Move' }}
			</BaseButton>
		</div>
	</Form>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { Form, Field } from 'vee-validate';

import { storeToRefs } from 'pinia';

import useDomainsStore from '@/stores/agent/agent-domains';
import useAlertStore from '@/stores/alerts';

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import RadioGroup from '@/components/ui/RadioGroup.vue';

const domainStore = useDomainsStore();
const alertStore = useAlertStore();

const domainTypeOptions = [
	{ value: 'sf_microsite', text: 'Secure Microsite Proxy' },
	{ value: 'm1', text: 'M1' },
	{ value: 'm2', text: 'M2' },
	{ value: 'parked', text: 'Secure Parked' },
];

function aliasLength(value) {
	if (value === undefined) {
		return true;
	} else if (value.length === 4) {
		return true;
	} else if (value.length === 6) {
		return true;
	}
	return 'Alias should be 4 or 6 characters';
}

function domainTypeChosen(value) {
	if (!domainTypeOptions.map(option => option.value).includes(value)) {
		return 'Domain Type must be chosen';
	}
	return true;
}

const status = ref('loading');

const responseErrors = ref(null);

const shouldRemoveRedirects = ref(true);
const selectedAlias = ref('');
const selectedDomain = ref(null);
const selectedProductType = ref(null);

const { movableDomains } = storeToRefs(domainStore);

const activeMovableDomains = computed(() =>
	movableDomains.value.filter(domain => domain.active && domain.can_move)
);

const inactiveOrUnmovableDomains = computed(() =>
	movableDomains.value.filter(domain => !domain.active || !domain.can_move)
);

async function onMove() {
	status.value = 'moving';
	try {
		const errors = await domainStore.moveDomain({
			target_alias: selectedAlias.value,
			target_product_type: selectedProductType.value,
			remove_redirects: shouldRemoveRedirects.value,
			name: selectedDomain.value,
		});
		status.value = 'done';

		await domainStore.getMovableDomains();

		if (errors) {
			responseErrors.value = errors;
		}
	} catch (error) {
		status.value = null;
		alertStore.addAlert({ error, message: error.message });
	}
}
onMounted(async () => {
	await domainStore.getMovableDomains();
	status.value = null;
	responseErrors.value = null;
});
</script>

<style scoped></style>
