<template>
	<transition v-if="overlay" name="fade">
		<div
			v-if="loading"
			class="absolute inset-0 z-30 grid place-items-center bg-opacity-85 dark:bg-opacity-85"
			:class="bgClass || 'bg-white dark:bg-gray-900'"
		>
			<div class="flex flex-col items-center justify-center gap-2" :class="spinnerClass">
				<div class="spinner-container">
					<svg class="spinner" :class="small ? 'h-6 w-6' : 'h-8 w-8'" viewBox="0 0 50 50">
						<circle :class="[spinnerColor]" cx="25" cy="25" r="20" />
					</svg>
				</div>
				<div v-if="text" class="animate-pulse font-semibold">{{ text }}</div>
			</div>
		</div>
	</transition>
	<div v-else-if="loading" class="m-auto flex flex-col items-center justify-center gap-2">
		<div class="spinner-container">
			<svg class="spinner" :class="small ? 'h-6 w-6' : 'h-8 w-8'" viewBox="0 0 50 50">
				<circle :class="[spinnerColor]" cx="25" cy="25" r="20" />
			</svg>
		</div>
		<div v-if="text" class="animate-pulse font-semibold">{{ text }}</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	text: { type: String, default: null },
	small: { type: Boolean, default: false },
	gray: { type: Boolean, default: false },
	blue: { type: Boolean, default: false },
	overlay: { type: Boolean, default: false },
	loading: { type: Boolean, default: true },
	bgClass: { type: String, default: null },
	spinnerClass: { type: String, default: null },
});

const spinnerColor = computed(() => {
	if (props.gray) {
		return 'stroke-gray-500 dark:stroke-gray-300';
	}
	if (props.blue) {
		return 'stroke-blue-700';
	}
	return 'stroke-orange-500';
});
</script>

<style lang="scss">
.spinner {
	animation: rotate 2s linear infinite;
}

.spinner circle {
	fill: none;
	stroke-width: 7;
	stroke-linecap: round;
	animation: dash 1.5s ease-in-out infinite;
	transform-origin: 50% 50%;
}

/* Rotate the entire spinner */
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

/* Create the dasharray effect */
@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: -125;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
