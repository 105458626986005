<template>
	<label
		:class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
		:for="inputID"
		class="relative inline-flex select-none items-center gap-2"
	>
		<span class="relative grid place-items-center">
			<Field v-slot="{ field }" :name="name" type="checkbox" :value="value" :rules="rules">
				<input
					:id="inputID"
					:class="[colorClass, roundedClass, { disabled }]"
					:disabled="disabled"
					:name="name"
					v-bind="field"
					:checked="modelValue"
					class="relative h-5 w-5 appearance-none border-2 border-current bg-white outline-none ring-current ring-offset-2 ring-offset-white transition-shadow checked:bg-current focus-visible:ring-2 dark:bg-gray-900 dark:ring-offset-gray-800 dark:checked:bg-current"
					type="checkbox"
					@click="$emit('click', $event)"
					@change="$emit('update:model-value', $event.target.checked)"
				/>
			</Field>
			<svg
				class="absolute left-0 top-0 h-full w-full fill-white dark:fill-gray-900"
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"
				/>
			</svg>
		</span>
		<span
			v-if="$slots.default"
			:class="disabled ? 'text-gray-400' : 'text-gray-800 dark:text-gray-200'"
		>
			<slot />
		</span>
	</label>
</template>

<script setup>
import { computed } from 'vue';
import { getColorClass, getRoundedClass } from '@/utils/styles';
import { Field } from 'vee-validate';

defineEmits(['update:model-value', 'click']);

const props = defineProps({
	name: { type: String, required: true },
	modelValue: { default: true, type: Boolean },

	rounded: { type: [String, Boolean], default: 'sm' },
	disabled: { type: Boolean, default: false },
	color: { type: String, default: 'gray' },

	// for vee-validate
	value: { type: null, required: false },
	rules: {
		type: [String, Object],
		default: null,
	},
});

const roundedClass = computed(() => getRoundedClass(props.rounded));
const colorClass = computed(() => getColorClass(props.color));
const inputID = computed(() => `checkbox-option-${props.name.replaceAll(/\s/g, '_')}`);
</script>

<style lang="scss" scoped>
.mirusOrange {
	@apply text-orange-500;
}
.successGreen {
	@apply text-green-600;
}
.mirusGray {
	@apply text-gray-800 dark:text-gray-300;
}
.errorRed {
	@apply text-red-500;
}
.infoBlue {
	@apply text-blue-500;
}
.warnYellow {
	@apply text-yellow-300;
}
.disabled {
	@apply text-gray-400;
}
</style>
