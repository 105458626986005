<template>
	<div class="inline-flex items-center" :class="{ 'flex-col text-center': showText }">
		<div v-if="!showText" class="inline-flex items-center dark:text-gray-900">
			<slot />
			<BaseTooltip
				text="Click for info"
				:position="tooltipPosition"
				:tooltip-id="keyword"
				class="ml-1"
			>
				<button
					type="button"
					class="rounded-full leading-none outline-none ring-blue-400 ring-offset-2 focus:ring dark:ring-offset-gray-700"
					@click="dialog = true"
				>
					<FAIcon
						:icon="icon"
						:size="iconSize"
						class="text-blue-700 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-500"
					/>
				</button>
			</BaseTooltip>
		</div>

		<button
			v-else
			class="inline-flex flex-col text-center text-blue-800 dark:text-blue-400"
			:title="buttonTitle"
			@click="dialog = true"
		>
			<slot />
			<span class="flex items-center" data-testid="info-link-text">
				<FAIcon class="mr-1" :size="iconSize" :icon="icon" />
				<span>Click for info</span>
			</span>
		</button>

		<BaseDialog v-model="dialog" :aria-label="keyword" dense max-width="700">
			<KeywordContent :dialog="dialog" :keyword="keyword" @close="dialog = false" />
		</BaseDialog>
	</div>
</template>

<script setup>
import { ref } from 'vue';

import KeywordContent from '@/components/glossary/KeywordContent';
import BaseTooltip from '@/components/ui/BaseTooltip';
import BaseDialog from '@/components/ui/BaseDialog';

defineProps({
	keyword: {
		required: true,
		type: String,
	},
	icon: {
		type: String,
		default: 'circle-info',
	},
	iconSize: {
		type: String,
		default: 'lg',
	},
	showText: {
		type: Boolean,
		default: true,
	},
	tooltipPosition: {
		type: String,
		default: 'top',
	},
	buttonTitle: {
		type: String,
		default: '',
	},
});

const dialog = ref(false);
</script>
