<template>
	<Form
		v-slot="{ meta, handleSubmit }"
		class="rounded-lg bg-white p-4 text-gray-700 dark:bg-gray-800 dark:text-gray-300 @container"
		tag="div"
	>
		<h3 class="pb-4 text-2xl font-semibold leading-none text-gray-700 dark:text-gray-100">
			Search Email Logs (last 7 days)
		</h3>
		<form class="grid grid-cols-2 gap-4" @submit.prevent="handleSubmit(getEmailSearchResults)">
			<Field v-slot="{ errors, field }" :rules="{ required: !toEmail }" name="from">
				<TextFieldInput
					v-model="fromEmail"
					label="From Email"
					placeholder="from email"
					v-bind="field"
				>
					<template v-if="errors.length > 0" #message>
						You must fill in at least one field
					</template>
				</TextFieldInput>
			</Field>

			<Field v-slot="{ errors, field }" :rules="{ required: !fromEmail }" name="to">
				<TextFieldInput
					v-model="toEmail"
					label="To Email"
					placeholder="to email"
					v-bind="field"
				>
					<template v-if="errors.length > 0" #message>
						You must fill in at least one field
					</template>
				</TextFieldInput>
			</Field>

			<div class="col-span-2 flex gap-2 justify-end">
				<BaseButton
					:disabled="!showDownload"
					:href="showDownload ? downloadApiUrl : null"
					class="@xl:w-1/4"
					color="gray"
				>
					Download CSV
				</BaseButton>
				<BaseButton
					:disabled="!meta.dirty || !meta.valid"
					class="@xl:w-1/4"
					type="submit"
				>
					Search
				</BaseButton>
			</div>
		</form>
	</Form>

	<div v-if="loading" class="mt-4 rounded-lg bg-white p-4 py-16 dark:bg-gray-900">
		<LoadingSpinner text="Loading search results..." />
	</div>

	<SortableTable
		v-else
		:headers="headers"
		:items="parsedEmails"
		title="Search Results"
		item-unique-key="arrival"
		show-pagination
		show-search
		dense
		show-title
		class="mt-4 shrink grow-0 rounded-lg bg-white p-4 dark:bg-gray-800"
		:expand-all="false"
	>
		<template #item.arrival="{ arrival }">
			{{ format(new Date(arrival), 'MMM dd, yyy h:mm:ss a') }}
		</template>
		<template #item.from="{ from }">
			{{ from }}
		</template>
		<template #item.to="{ to }">
			{{ to }}
		</template>
		<template #item.event="{ event }">
			<div class="w-fit rounded-md px-2 py-1 text-sm" :class="getEventClasses(event)">
				{{ event }}
			</div>
		</template>
		<template #item.message="{ message }">
			{{ shorthandMessage(message) }}
		</template>
		<template #item_expanded="{ message }">
			<div class="p-4">
				<span v-if="!message"> null </span>
				<pre v-else lang="json">{{ formatMessage(message) }}</pre>
			</div>
		</template>
	</SortableTable>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Form, Field } from 'vee-validate';

import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import SortableTable from '@/components/ui/SortableTable.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

const headers = [
	{ text: 'Arrival', value: 'arrival', sortable: true, wrap: false },
	{ text: 'From', value: 'from', filterable: true, sortable: true, wrap: false },
	{ text: 'To', value: 'to', filterable: true, sortable: true, wrap: false },
	{ text: 'Event', value: 'event' },
	{ text: 'Message', value: 'message', truncate: true },
];

const loading = ref(false);
const fromEmail = ref(null);
const toEmail = ref(null);
const emails = ref([]);

const parsedEmails = computed(() =>
	emails.value.map(email => ({ ...email, expandable: Boolean(email.message) }))
);

const showDownload = computed(() => {
	return !loading.value && emails.value?.length;
});

const searchApiUrl = computed(() => {
	const params = {};

	if (toEmail.value !== null) {
		params.to = toEmail.value;
	}

	if (fromEmail.value !== null) {
		params.from = fromEmail.value;
	}
	return `api/agents/search_emails/?${new URLSearchParams(params)}`;
});

const downloadApiUrl = computed(() => {
	return `/${searchApiUrl.value}&format=csv`;
});

function getEventClasses(event) {
	event = event.toLowerCase();
	switch (event) {
		case 'delivered':
			return 'bg-green-200 text-green-800 dark:bg-green-800 dark:text-green-200';
		case 'rejected':
			return 'bg-red-200 text-red-800 dark:bg-red-800 dark:text-red-100';
		case 'hard bounce':
			return 'bg-red-200 text-red-800 dark:bg-red-800 dark:text-red-100';
		default:
			return 'bg-gray-200 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
	}
}

async function getEmailSearchResults() {
	emails.value = [];
	loading.value = true;

	try {
		const result = await useApi(searchApiUrl.value, {
			message: 'There was a issue with pulling your email search. Please try again later!',
		}).json();

		emails.value = result.data.value?.emails;
	} catch (error) {
		console.error(error);
	} finally {
		loading.value = false;
	}
}

function shorthandMessage(message) {
	if (!message) {
		return null;
	}

	try {
		const jsonData = JSON.parse(message);
		return jsonData.msg;
	} catch (error) {
		console.warn(error);
		// if parse fails, fall back to the original string
		return message;
	}
}

function formatMessage(message) {
	// message can be a stringified JSON object or a plain string
	try {
		return JSON.parse(message);
	} catch (error) {
		console.error(error);
		// if parse fails, fall back to the original string
		return message;
	}
}
</script>

<style module lang="scss">
.table {
}
.body {
	overflow: auto;
}
</style>
