<template>
	<div class="flex flex-col gap-2 overflow-hidden rounded-lg bg-white dark:bg-gray-800">
		<LoadingSpinner v-if="activeTab === null" text="Getting jobs..." class="min-h-56" />
		<TabGroup v-else id="mirus-controls-locations" v-model:active-tab="activeTab" :tabs="tabs">
			<template v-for="tab in tabs" :key="tab.path" #[`tabContent.${tab.path}`]>
				<div class="overflow-auto rounded-md bg-white p-2 dark:bg-gray-800">
					<Form
						ref="observer"
						v-slot="{ meta }"
						class="m-4 flex w-2/3 flex-col gap-2"
						@submit="saveUrl"
					>
						<p class="text-lg">
							Current Microsite Url:
							<a :href="jobListing?.microsite_url" rel="noopener noreferrer">
								{{ jobListing?.microsite_url }}
							</a>
						</p>
						<Field
							ref="listingUrlValidator"
							v-slot="{ errors, field }"
							:rules="{
								url: true,
								required: true,
								regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
							}"
							name="Listing Url"
						>
							<TextFieldInput
								id="listing-url"
								ref="listingUrlInput"
								v-model="listingUrl"
								label="Microsite URL Override"
								v-bind="field"
								:error-message="errors[0]"
							>
								<template #hint> Enter url to override microsite url </template>
							</TextFieldInput>
						</Field>
						<div class="mt-1">
							<div class="flex items-center gap-2">
								<BaseButton
									:disabled="!meta.dirty || !meta.valid"
									color="primary"
									@click="saveUrl()"
								>
									<span class="text-sm font-medium">Save & Crawl</span>
								</BaseButton>
								<BaseButton
									:disabled="!jobListing?.url_override"
									color="gray"
									@click="clearUrl()"
								>
									<span class="text-sm font-medium">Clear Override</span>
								</BaseButton>
								<BaseButton class="" color="gray" @click="crawlJobs()">
									<span class="text-sm font-medium">Crawl</span>
								</BaseButton>
							</div>
							<div class="mt-2">
								<p v-if="!isCrawling">
									Last crawl:
									{{ lastCrawlRelativeDate }}
								</p>
								<LoadingSpinner v-else text="Crawling Microsite..." />
							</div>
						</div>
					</Form>
					<div
						v-if="selectedAgentLocation?.job_listing?.jobs?.length > 0"
						class="m-4 mt-6 flex flex-row gap-4"
					>
						<div>
							<p class="mb-0 pb-1 text-lg">Open Positions With Complete Data</p>
							<ul class="flex flex-col gap-2 pl-0">
								<li v-for="job in jobsSplitByCompletion.complete" :key="job.url">
									<a :href="job.url">{{ job.title }}</a>
								</li>
							</ul>
						</div>
						<div>
							<p class="mb-0 pb-1 text-lg">Open Positions With Missing Data</p>
							<ul class="flex flex-col gap-2 pl-0">
								<li v-for="job in jobsSplitByCompletion.incomplete" :key="job.url">
									<a :href="job.url">{{ job.title }}</a>
								</li>
							</ul>
						</div>
					</div>
					<div v-else-if="isCareerPlugUrl()" class="m-4 mt-6">
						<p v-if="jobListing?.active">There are no open jobs right now.</p>
						<p v-else>Couldn't find any jobs. There may be an issue with the url.</p>
					</div>
				</div>
			</template>
		</TabGroup>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { Form, Field } from 'vee-validate';
import { storeToRefs } from 'pinia';
import { parseISO, formatDistanceToNow } from 'date-fns';

import TabGroup from '@/components/ui/TabGroup.vue';
import BaseButton from '@/components/ui/BaseButton';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

import useAgentLocationsStore from '@/stores/agent/agent-locations';

const agentLocationStore = useAgentLocationsStore();
const { agentLocations } = storeToRefs(agentLocationStore);

const listingUrl = ref('');
const isCrawling = ref(false);
const activeTab = ref(null);
const tabs = ref([]);

const lastCrawlRelativeDate = computed(() =>
	jobListing.value?.last_crawl
		? formatDistanceToNow(parseISO(jobListing.value?.last_crawl), {
				addSuffix: true,
			})
		: 'never'
);

const jobsSplitByCompletion = computed(() => {
	const complete = [];
	const incomplete = [];

	jobListing.value?.jobs.forEach(job => {
		if (['url', 'employment_type', 'title', 'location'].every(field => job[field])) {
			complete.push(job);
			return;
		}
		incomplete.push(job);
	});
	return { complete, incomplete };
});

const url = computed(() => jobListing.value?.url_override || jobListing.value?.microsite_url);

const selectedAgentLocation = computed(() =>
	agentLocations.value?.find(location => location.office_associate_id === activeTab.value)
);

const jobListing = computed(() => selectedAgentLocation.value?.job_listing);

function isCareerPlugUrl() {
	const urlOrNull = url?.value;
	return urlOrNull ? urlOrNull.includes('sfagentjobs.com') : false;
}

async function getJobs() {
	await agentLocationStore.get();
	listingUrl.value = jobListing.value?.url_override;
}

async function crawlJobs() {
	isCrawling.value = true;
	await agentLocationStore.crawlJobs(selectedAgentLocation.value?.office_associate_id);
	listingUrl.value = jobListing.value?.url_override;
	isCrawling.value = false;
}

async function saveUrl() {
	isCrawling.value = true;
	await agentLocationStore.updateJobListing(
		selectedAgentLocation.value?.office_associate_id,
		listingUrl.value
	);
	isCrawling.value = false;
}

async function clearUrl() {
	isCrawling.value = true;
	listingUrl.value = '';
	await agentLocationStore.updateJobListing(
		selectedAgentLocation.value?.office_associate_id,
		null
	);
	isCrawling.value = false;
}

watch(activeTab, () => {
	listingUrl.value = jobListing.value?.url_override;
});

onMounted(async () => {
	await getJobs();

	const sortedLocations = agentLocations.value?.toSorted((a, b) => {
		if (a.job_listing && b.job_listing) {
			if (a.job_listing.active && b.job_listing.active) {
				return 0;
			} else if (a.job_listing.active) {
				return -1;
			}
			return 1;
		} else if (a.job_listing) {
			return -1;
		}
		return 1;
	});
	tabs.value = sortedLocations?.map(location => {
		const locationName = `${location.office_location.city || 'city'}, ${location.office_location.state || 'state'}`;
		return { name: locationName, path: location.office_associate_id };
	});

	activeTab.value = tabs.value[0].path;
	listingUrl.value = jobListing.value?.url_override;
});
</script>
