<template>
	<div :id="contentId" ref="contentRef">
		<transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
			<template v-if="expanded">
				<div>
					<slot />
				</div>
			</template>
		</transition>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
	scrollIntoView: { type: Boolean, default: false },
	expanded: { type: Boolean, default: false },
	contentId: { type: String },
});
const contentRef = ref(null);

function enter(element) {
	const width = getComputedStyle(element).width;

	element.style.width = width;
	element.style.position = 'absolute';
	element.style.visibility = 'hidden';
	element.style.height = 'auto';

	const height = getComputedStyle(element).height;

	element.style.width = null;
	element.style.position = null;
	element.style.visibility = null;
	element.style.height = 0;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = height;
	});
}

function afterEnter(element) {
	element.style.height = 'auto';

	if (props.scrollIntoView) {
		contentRef.value.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}
}

function leave(element) {
	const height = getComputedStyle(element).height;

	element.style.height = height;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = 0;
	});
}
onMounted(() => {
	if (props.expanded && props.scrollIntoView) {
		setTimeout(() => {
			contentRef.value?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}, 100);
	}
});
</script>
<style scoped lang="scss">
* {
	will-change: height;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
	transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
	height: 0;
}
</style>
