import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';

import useEmailStore from '@/stores/agent/agent-emails';

import { useApi } from '@/composables/useApi';

export default defineStore('agent/appt-scheduler', () => {
	// THIS SHOULD BE FALSE IN PRODUCTION UNTIL APPOINTMENT SCHEDULING IS RELEASED
	const ENABLED_FOR_ALL_AGENTS = ref(false);
	// THIS SHOULD BE FALSE IN PRODUCTION UNTIL APPOINTMENT SCHEDULING IS RELEASED

	const VueRoute = useRoute();
	const emailStore = useEmailStore();

	const appointments = ref([]);
	const schedules = ref([]);
	const hasAS = ref(false);
	const enablingAS = ref(false);
	const disablingAS = ref(false);
	const loadingAppointments = ref(false);
	const loadingSchedules = ref(false);
	const loadingOOOBlocks = ref(false);
	const savingChangesTimeout = ref(null);
	const numberOfChangesBeingSaved = ref(0);
	const saveStatusRef = ref('saving');
	const savingChanges = ref(false);
	const agencyWideCalendars = ref(null);
	const subscriptionJustEnabled = ref(false);
	const subscriptionJustCanceled = ref(false);
	const emailRecipients = ref([]);

	const saveStatus = computed({
		get() {
			return saveStatusRef.value;
		},
		set(newValue) {
			clearTimeout(savingChangesTimeout.value);
			if (newValue === 'saved') {
				if (--numberOfChangesBeingSaved.value === 0) {
					saveStatusRef.value = 'saved';
				}
				savingChangesTimeout.value = setTimeout(() => {
					savingChanges.value = false;
				}, 3000);
			} else if (newValue === 'saving') {
				numberOfChangesBeingSaved.value++;
				saveStatusRef.value = 'saving';
				savingChanges.value = true;
			}
		},
	});
	const calendarSubscriptions = computed(() => {
		const calendarSubscriptions = [];
		for (const schedule of schedules.value) {
			calendarSubscriptions.push({ label: schedule.label, calendars: schedule.calendars });
		}
		if (agencyWideCalendars.value) {
			calendarSubscriptions.push({
				label: 'Agency-Wide',
				calendars: agencyWideCalendars.value,
			});
		}
		return calendarSubscriptions;
	});

	async function getAS(agentId = VueRoute.params.agentID) {
		if (agentId !== '0XDEADBEEFD' && !ENABLED_FOR_ALL_AGENTS.value) {
			this.$reset;
			return;
		}

		const { data } = await useApi(`api/agents/${agentId}/scheduler_subscription/`, {
			message: `There was an error getting your appointment scheduler subscription. Please try again later.`,
		}).json();

		hasAS.value = data.value.active;
		return data.value.active;
	}

	async function enableAS() {
		enablingAS.value = true;
		await useApi(`api/agents/${VueRoute.params.agentID}/scheduler_subscription/enable/`, {
			message: `There was an error signing up for appointment scheduling. Please try again later.`,
		}).put();
		subscriptionJustEnabled.value = true;
		subscriptionJustCanceled.value = false;

		getAS();
		emailStore.ensureEmails();
		await getSchedules();
		if (!schedules.value.length) {
			await createSchedule();
		}

		enablingAS.value = false;
	}

	async function disableAS() {
		disablingAS.value = true;
		await useApi(`api/agents/${VueRoute.params.agentID}/scheduler_subscription/disable/`, {
			message: `There was an error canceling your appointment scheduling. Please try again later.`,
		}).put();
		subscriptionJustEnabled.value = false;
		subscriptionJustCanceled.value = true;
		await getAS();
		disablingAS.value = false;
	}

	async function getAppointments(agentId = VueRoute.params.agentID) {
		loadingAppointments.value = true;
		const { data, statusCode } = await useApi(`api/agents/${agentId}/appointments/`, {
			message: `There was an error processing the request. Please try again later.`,
		}).json();

		if (statusCode.value === 200) {
			appointments.value = data.value.map(appt => ({
				...appt,
				assigned_to_name: appt.assigned_to.name,
				location_name: appt.location?.office_location.city ?? 'Virtual',
				status: appt.status ?? 'scheduled',
				date: format(new Date(appt.start_at), `iiiiii MMM dd, yyy`),
			}));
		}
		loadingAppointments.value = false;
	}

	async function cancelAppointment(id) {
		const { data, statusCode } = await useApi(`api/agents/${VueRoute.params.agentID}/appointments/${id}/`, {
			message: `There was an error canceling that appointment. Please try again later.`,
		}).delete().json();
		return {
			statusCode: statusCode.value,
			data: data?.value || {},
		}
	}

	async function rescheduleAppointment(id, newData) {
		const { data, statusCode } = await useApi(`api/agents/${VueRoute.params.agentID}/appointments/${id}/reschedule/`, {
			message: `There was an error rescheduling that appointment. Please try again later.`,
		}).post(newData).json();
		return {
			statusCode: statusCode.value,
			data: data?.value || {},
		}
	}

	async function getSchedules(agentId = VueRoute.params.agentID) {
		loadingSchedules.value = true;
		const { data, statusCode } = await useApi(`api/agents/${agentId}/schedules/`, {
			message: `There was an error getting your availability schedules. Please try again later.`,
		}).json();
		if (statusCode.value === 200) {
			schedules.value = data.value.map(serializeSchedule);
		}
		loadingSchedules.value = false;
	}

	async function getSchedule({ agentId = VueRoute.params.agentID, scheduleId }) {
		loadingSchedules.value = true;
		const { data, statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/`,
			{
				message: `There was an error retrieving your schedule data. Please try again later.`,
			}
		).json();
		if (statusCode.value === 200) {
			const existingScheduleIndex = schedules.value.findIndex(
				schedule => schedule.id === data.value.id && schedule.agent === data.value.agent
			);
			if (existingScheduleIndex >= 0) {
				schedules.value.splice(existingScheduleIndex, 1, serializeSchedule(data.value));
			} else {
				schedules.value.push(serializeSchedule(data.value));
				schedules.value.sort((a, b) => a.id - b.id);
			}
		}
		loadingSchedules.value = false;
	}

	function serializeSchedule(schedule) {
		return {
			...schedule,
			availability: schedule.availability.map(a => ({
				...a,
				start: a.start.slice(0, -3),
				end: a.end.slice(0, -3),
				recurrences: convertRRuleToSimpleArray(a.recurrences),
			})),
		};
	}

	async function createSchedule() {
		saveStatus.value = 'saving';
		loadingSchedules.value = true;

		const uid = crypto.randomUUID().slice(0, 3);
		const newSchedule = {
			agent: VueRoute.params.agentID,
			label: `New Schedule ${uid}`,
			domains: [],
		};

		const { statusCode } = await useApi(`api/agents/${VueRoute.params.agentID}/schedules/`, {
			message: `There was an error creating your schedule. Please try again later.`,
		}).post(newSchedule);

		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedules();
	}

	async function updateSchedule({ id, newData }) {
		loadingSchedules.value = true;
		saveStatus.value = 'saving';

		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${id}/`,
			{
				message: `There was an error updating your schedule. Please try again later.`,
			}
		).patch(newData);

		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ scheduleId: id });
	}

	async function deleteSchedule(id) {
		loadingSchedules.value = true;
		saveStatus.value = 'saving';
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${id}/`,
			{
				message: `There was an error deleting your schedule. Please try again later.`,
			}
		).delete();
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedules();
	}

	async function createAvailabilityBlock({
		agentId = VueRoute.params.agentID,
		associateId,
		scheduleId,
		location,
	}) {
		saveStatus.value = 'saving';

		const newAvailabilityBlock = {
			staff: associateId === agentId ? null : associateId,
			agent: associateId === agentId ? associateId : null,
			start: '09:00',
			end: '17:00',
			recurrences: 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR',
			meeting_lengths: [15, 30],
			communication_mediums: ['in person', 'phone'],
			location: location,
			schedule: scheduleId,
		};

		const { statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/availability/`,
			{
				message: `There was an error creating your availability block. Please try again later.`,
			}
		).post(newAvailabilityBlock);

		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
	}

	async function updateAvailabilityBlock({
		agentId = VueRoute.params.agentID,
		scheduleId,
		id,
		newData,
	}) {
		loadingSchedules.value = true;
		saveStatus.value = 'saving';

		const { statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/availability/${id}/`,
			{
				message: `There was an error updating your availability block. Please try again later.`,
			}
		).patch({
			...newData,
			...(newData.recurrences && {
				recurrences: convertRecurrenceArrayToRRule(newData.recurrences),
			}),
		});

		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
	}

	async function deleteAvailabilityBlock({ agentId = VueRoute.params.agentID, scheduleId, id }) {
		saveStatus.value = 'saving';

		const { statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/availability/${id}/`,
			{
				message: `There was an error deleting your availability block. Please try again later.`,
			}
		).delete();
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
	}

	async function createOOOBlock({ agentId = VueRoute.params.agentID, scheduleId, associateIds }) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const newOOOBlock = {
			start: '9:00:00',
			end: '17:00:00',
			date: new Date().toISOString().split('T')[0],
			schedule: scheduleId,
			associate_ids: associateIds,
			repeats_annually: false,
		};
		const { data, statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/out_of_office_blocks/`,
			{
				message: `There was an error creating your out of office block. Please try again later.`,
			}
		).post(newOOOBlock);
		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
		loadingOOOBlocks.value = false;
		return JSON.parse(data.value);
	}

	async function updateOOOBlock({ agentId = VueRoute.params.agentID, scheduleId, id, newData }) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const { statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/out_of_office_blocks/${id}/`,
			{
				message:
					'There was an error updating your out of office block. Please try again later.',
			}
		).put(newData);
		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
		loadingOOOBlocks.value = false;
	}

	async function deleteOOOBlock({ agentId = VueRoute.params.agentID, scheduleId, id }) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const { statusCode } = await useApi(
			`api/agents/${agentId}/schedules/${scheduleId}/out_of_office_blocks/${id}/`,
			{
				message:
					'There was an error deleting your out of office block. Please try again later.',
			}
		).delete();
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
		await getSchedule({ agentId, scheduleId });
		loadingOOOBlocks.value = false;
	}

	async function getAgencyWideCalendars() {
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/calendars/`,
			{
				message:
					'There was an error getting your calendar subscription links. Please try again later.',
			}
		).json();
		if (statusCode.value === 200) {
			agencyWideCalendars.value = data.value;
		}
	}

	async function getEmailRecipients() {
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/appointment_notification_recipients/`,
			{
				message:
					'There was an error getting your email recipients. Please try again later.',
			}
		).json();
		if (statusCode.value === 200) {
			emailRecipients.value = data.value.recipients;
		}
	}

	async function updateEmailRecipients(newData) {
		saveStatus.value = 'saving';
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/appointment_notification_recipients/`,
			{
				message:
					'There was an error updating your email recipients. Please try again later.',
			}
		).patch(newData);
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
	}

	function convertRRuleToSimpleArray(rrule) {
		const weeklyAvailability = [false, false, false, false, false, false, false];
		const daysMap = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

		const bydayMatch = rrule.match(/BYDAY=([A-Z,]+)/);
		if (bydayMatch) {
			const days = bydayMatch[1].split(',');
			days.forEach(day => {
				const index = daysMap[day];
				if (index !== undefined) {
					weeklyAvailability[index] = true;
				}
			});
		}
		return weeklyAvailability;
	}

	function convertRecurrenceArrayToRRule(recurrenceArray) {
		if (!recurrenceArray) {
			return '';
		}
		if (!recurrenceArray.includes(true)) {
			return '';
		}
		let rruleString = 'RRULE:FREQ=WEEKLY;BYDAY=';
		const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
		const daysToInclude = [];
		for (let i = 0; i < recurrenceArray.length; i++) {
			if (recurrenceArray[i]) {
				daysToInclude.push(days[i]);
			}
		}
		rruleString += daysToInclude.join(',');
		return rruleString;
	}

	function myAvailabilityBlocks(availability, associateId) {
		return availability
			.filter(aBlock => [aBlock.agent, aBlock.staff].includes(associateId))
			.sort((a, b) => a.id - b.id);
	}

	return {
		ENABLED_FOR_ALL_AGENTS,

		agencyWideCalendars,
		calendarSubscriptions,
		emailRecipients,

		hasAS,
		enablingAS,
		disablingAS,
		getAS,
		enableAS,
		disableAS,
		subscriptionJustEnabled,
		subscriptionJustCanceled,

		getAppointments,
		loadingAppointments,
		appointments,
		cancelAppointment,
		rescheduleAppointment,

		getSchedules,
		getSchedule,
		loadingSchedules,
		schedules,
		createSchedule,
		updateSchedule,
		deleteSchedule,

		createAvailabilityBlock,
		updateAvailabilityBlock,
		deleteAvailabilityBlock,

		loadingOOOBlocks,
		createOOOBlock,
		updateOOOBlock,
		deleteOOOBlock,

		getAgencyWideCalendars,
		getEmailRecipients,
		updateEmailRecipients,

		saveStatus,
		savingChanges,

		myAvailabilityBlocks,
	};
});
