import { defineStore } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { ref, computed } from 'vue';

const { width } = useWindowSize();

export default defineStore('navigation', () => {
	const showHeader = ref(false);
	const showNavigation = ref(false);
	const headerLabel = ref('');
	const drawerWidth = ref(null);
	const collapseTo = ref('none');
	const expandTo = ref('lg');
	const navSkipLink = ref(null);
	const skipLink = ref(null);
	const drawerOpen = computed(() => {
		return drawerWidth.value === expandTo.value;
	});

	function handleClick() {
		if (window.innerWidth < 640) {
			drawerWidth.value = collapseTo.value;
		}
	}

	return {
		showHeader,
		showNavigation,
		headerLabel,
		drawerOpen,
		drawerWidth,
		collapseTo,
		expandTo,
		navSkipLink,
		skipLink,
		handleClick,
	};
});
